var transition_module = (function() {
   
    /**
     * Inizializza il modulo
     * @access  Public
     */
    var init = function() {
        _bind_transition_events();

    };

    var _bind_transition_events = function()
    {
        Barba.Dispatcher.on('linkClicked', function(HTMLElement, MouseEvent) {
            if($('.menu-trigger').hasClass('active'))
                $('.menu-trigger').trigger('click');
        });

        var links = document.querySelectorAll('a[href]');
        var cbk = function(e) {
         if(e.currentTarget.href === window.location.href) {
           e.preventDefault();
           e.stopPropagation();
         }
        };

        for(var i = 0; i < links.length; i++) {
          links[i].addEventListener('click', cbk);
        }

        //Rimuove l'eventuale blocco dello scroll al cambio pagina
        Barba.Dispatcher.on('initStateChange', function(currentStatus) {
          $('body').removeClass('no-overflow');
        });

        //Lancia i vari moduli quando la nuova pagina è pronta
        Barba.Dispatcher.on('newPageReady', function(currentStatus) {
          helper_module.do_the_magic();
        });

        /*****************************************************************/
        /*****************************************************************/
        /*****************************************************************/

        /*Homepage*/
        var Homepage = Barba.BaseView.extend({
          namespace: 'homepage',
          onEnter: function() {
                scroll_module.init();
                slider_module.init();
          },
          onEnterCompleted: function() {
              // The Transition has just finished.
                console.log('Home loaded');
                $('body').addClass('home');
          },
          onLeave: function() {
              // A new Transition toward a new page has just started.
                $('body').removeClass('home');
          },
          onLeaveCompleted: function() {
              // The Container has just been removed from the DOM.
          }
        });

        /*404*/
        var NotFound = Barba.BaseView.extend({
          namespace: 'not-found',
          onEnter: function() {
          },
          onEnterCompleted: function() {
          },
          onLeave: function() {
          },
          onLeaveCompleted: function() {
          }
        });

        // Don't forget to init the view!
        NotFound.init();

        // Don't forget to init the view!
        Homepage.init();

        /*Ricerca*/
        var Ricerca = Barba.BaseView.extend({
          namespace: 'ricerca',
          onEnter: function() {
                scroll_module.init();
                slider_module.init();
          },
          onEnterCompleted: function() {
          },
          onLeave: function() {
          },
          onLeaveCompleted: function() {
          }
        });

        // Don't forget to init the view!
        Ricerca.init();

        /*Garofoli*/
        var Garofoli = Barba.BaseView.extend({
          namespace: 'garofoli',
          onEnter: function() {
              slider_module.init();
          },
          onEnterCompleted: function() {
          },
          onLeave: function() {
          },
          onLeaveCompleted: function() {
          }
        });

        // Don't forget to init the view!
        Garofoli.init();

        /*Gidea*/
        var Gidea = Barba.BaseView.extend({
          namespace: 'gidea',
          onEnter: function() {
          },
          onEnterCompleted: function() {
          },
          onLeave: function() {
          },
          onLeaveCompleted: function() {
          }
        });

        // Don't forget to init the view!
        Gidea.init();

        /*Hotel*/
        var Hotel = Barba.BaseView.extend({
          namespace: 'hotel',
          onEnter: function() {
          },
          onEnterCompleted: function() {
          },
          onLeave: function() {
          },
          onLeaveCompleted: function() {
          }
        });

        /*Formazione*/
        var Formazione = Barba.BaseView.extend({
          namespace: 'formazione',
          onEnter: function() {
          },
          onEnterCompleted: function() {
          },
          onLeave: function() {
          },
          onLeaveCompleted: function() {
          }
        });

        // Don't forget to init the view!
        Formazione.init();

        /*Porte*/
        var Porte = Barba.BaseView.extend({
          namespace: 'porte',
          onEnter: function() {
            //dropdown.init();
            slider_module.init();
            slider_door_collection_module.init();
          },
          onEnterCompleted: function() {
          },
          onLeave: function() {
          },
          onLeaveCompleted: function() {
          }
        });

        // Don't forget to init the view!
        Porte.init();

        /*FiltriPorte*/
        var FiltriPorte = Barba.BaseView.extend({
          namespace: 'filtri-porte',
          onEnter: function() {
            //dropdown.init();
          },
          onEnterCompleted: function() {
          },
          onLeave: function() {
          },
          onLeaveCompleted: function() {
          }
        });

        // Don't forget to init the view!
        FiltriPorte.init();

        /*Famiglia*/
        var Famiglia = Barba.BaseView.extend({
          namespace: 'famiglia',
          onEnter: function() {
            //dropdown.init();
            slider_module.init();
          },
          onEnterCompleted: function() {
          },
          onLeave: function() {
          },
          onLeaveCompleted: function() {
          }
        });

        // Don't forget to init the view!
        Famiglia.init();

        /*Filomuro*/
        var Filomuro = Barba.BaseView.extend({
          namespace: 'filomuro',
          onEnter: function() {
            //dropdown.init();
          },
          onEnterCompleted: function() {
          },
          onLeave: function() {
          },
          onLeaveCompleted: function() {
          }
        });

        // Don't forget to init the view!
        Filomuro.init();

        /*Contatti*/
        var Contatti = Barba.BaseView.extend({
          namespace: 'contatti',
          onEnter: function() {
            //dropdown.init();
            dropdown.updateSelects();
            gmap_module.init_contacts();
            contacts_form.init();
          },
          onEnterCompleted: function() {
          },
          onLeave: function() {
          },
          onLeaveCompleted: function() {
          }
        });

        // Don't forget to init the view!
        Contatti.init();

        /*Punti vendita*/
        var PuntiVendita = Barba.BaseView.extend({
          namespace: 'punti-vendita',
          onEnter: function() {
            //dropdown.init();
            gmap_module.init_resellers();
          },
          onEnterCompleted: function() {
          },
          onLeave: function() {
          },
          onLeaveCompleted: function() {
          }
        });

        // Don't forget to init the view!
        PuntiVendita.init();


        /*Generico*/
        var Generic = Barba.BaseView.extend({
          namespace: 'generic',
          onEnter: function() {
            //dropdown.init();
          },
          onEnterCompleted: function() {
          },
          onLeave: function() {
          },
          onLeaveCompleted: function() {
          }
        });

        // Don't forget to init the view!
        Generic.init();

        /*****************************************************************/
        /*****************************************************************/
        /*****************************************************************/

        Barba.Pjax.start();

        var FadeTransition = Barba.BaseTransition.extend({
          start: function() {
            /**
             * This function is automatically called as soon the Transition starts
             * this.newContainerLoading is a Promise for the loading of the new container
             * (Barba.js also comes with an handy Promise polyfill!)
             */

            // As soon the loading is finished and the old page is faded out, let's fade the new page
            Promise
              .all([this.newContainerLoading, this.fadeOut()])
              .then(this.fadeIn.bind(this));
          },

          fadeOut: function() {
            /**
             * this.oldContainer is the HTMLElement of the old Container
             */
            //$(this.oldContainer).removeClass('enterAnimation');
            //$(this.oldContainer).addClass('exitAnimation');
            $('.loader').addClass('active');

            return new Promise(function(resolve, reject) {
                window.setTimeout(function() {
                   resolve();
                }, 300);
            });
          },

          fadeIn: function() {
            /**
             * this.newContainer is the HTMLElement of the new Container
             * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
             * Please note, newContainer is available just after newContainerLoading is resolved!
             */

            var _this = this;
            var $el = $(this.newContainer);

            $('.loader').addClass('inactive');
            //$(this.oldContainer).hide().removeClass('exitAnimation');
            $('html, body').scrollTop('0px');

            //Chiudo sottomenu
            $('#menu-main_menu .sub-menu-trigger > a').removeClass('active');
            $('#menu-main_menu .sub-menu').removeClass('active').hide();

            window.setTimeout(function() {
               $('.loader').removeClass('active').removeClass('inactive');
            }, 1000);

            //$el.addClass('enterAnimation');
            _this.done();
          }
        });

        Barba.Pjax.getTransition = function() {
          return FadeTransition;
        };

    };

    return {
        init: init
    };
})();