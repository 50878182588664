var modal_module = (function() {

    var modal_trigger_class = '.modal-trigger';
    var modal_close_trigger_class = '.close-modal';

    //Layer
    var bkg_class = '.bkg-layer-trigger';
    var bkg_window_class = '.window-modal';

    var init = function()
    {
        _attach_trigger_class();
        _attach_bkg_event();
        _autoclick_single_catalog();
    };

    var _attach_trigger_class = function()
    {
        //Apertura modale
        $('body').on('click', modal_trigger_class, function(e)
        {
            e.preventDefault();
            e.stopPropagation();

            var target = $(this).data('target');
            _show_bkg_layer();
            _stop_scrolling();

            $(target).addClass('active');
            //Se modale video imposto l'iframe con codice video
            if($(target).hasClass('video'))
            {
                var video = $(this).data('video');
                _update_video(video, $(target));
            }

            var source = e.currentTarget;
            if ($(source).data('id')) {
                $(target).data('source-id', $(source).data('id'));
            }
        });

        //Chiusura modale
        $('body').on('click', modal_close_trigger_class, function(e)
        {
            e.preventDefault();
            e.stopPropagation();

            var $modal = $(this).closest('.mod');

            $modal.removeClass('active');
            _hide_bkg_layer();
            _init_scrolling();
            _empty_iframe($(this).parents('.mod'));

            if ($modal.attr('id') == 'modal-catalogue') {
                $('#modal-catalogue .modal-cont').empty().append($('#modal-catalogue').find('.form-template').html());
            }

            $modal.trigger('close-modal');
        });

        $('.modal-cont').on('click', function(e)
        {
            var $button = $(this).find(modal_close_trigger_class).eq(0);
            var icon = $button.find('i').get(0);


            if (e.target !== $button[0] && e.target !== icon) {
                e.stopPropagation();
            }
        });

        //Apertura modale registrazione
        $('#subscribe-trigger').on('click', function(e)
        {
            $(this).parents('.mod').removeClass('active');
        });

        $('#modal-change-password').on('close-modal', function(e)
        {
            $(this).find('form').removeClass('hidden').next('.change-password-success').addClass('hidden');
        });


        // window.blurTimeoutInterval = 1000 * 60 * 10;
        // window.blurTimeout = null;
        // window.canShowMessageOnBlur = true;

        // window.blurTimeout = setTimeout(function() {
        //     window.canShowMessageOnBlur = true;
        // }, window.blurTimeoutInterval);

        // $(window).blur(function() {
        //     if (window.canShowMessageOnBlur && $(window).outerWidth(true) > 768)
        //     {
        //         window.canShowMessageOnBlur = false;
        //         _show_newsletter_modal();
        //         clearTimeout(window.blurTimeout);
        //         window.blurTimeout = setTimeout(function() {
        //             window.canShowMessageOnBlur = true;
        //         }, window.blurTimeoutInterval);
        //     }
        // });
        // $('#newsletter').on('close-modal', function() {
        //     window.blurMessageVisible = false;
        // });



		/*7-4-2021 disabilito il pop-up*/
        window.canShowMessageOnBlur = false;
		
		/*
		window.canShowMessageOnBlur = true;		
        var isCookieSet = Cookies.get('newsletter') ? true : false;

        $(window).on('scroll', function() 
        {
            var scrollPercent = 100 * $(window).scrollTop() / ($(document).height() - $(window).height());
            if (scrollPercent >= 70 && window.canShowMessageOnBlur && !isCookieSet)
            {
                _show_newsletter_modal();
                Cookies.set('newsletter', 'true', { expires: 30 });
                window.canShowMessageOnBlur = false;
            }
        });
		*/
    };

    var _empty_iframe = function(_$mod)
    {
        _$mod.find('iframe').attr('src', '');
    };

    var _update_video = function(_video_code, _$modal)
    {
        //var src = 'https://www.youtube.com/embed/' + _video_code + '?autoplay=1';
        //_$modal.find('iframe').attr('src', src);	
			
		if(_$modal.find('iframe').hasClass('_iub_cs_activate-activated')){ 
			var src = 'https://www.youtube-nocookie.com/embed/' + _video_code + '?autoplay=1';
		}else{
			var id = document.getElementsByClassName("wpml-ls-vg-code");
			if (id.length > 0) {
				var src = '/wp-content/themes/garofoli/cookies-not-accepted/no-cookies-cat-3-' + id[0].textContent +'.html';
			}else{
				var src = '/wp-content/themes/garofoli/cookies-not-accepted/no-cookies-cat-3-it.html';
			}			
		}
		
		_$modal.find('iframe').attr('src', src);
		
    };

    var _show_bkg_layer = function()
    {
        $(bkg_class).addClass('active');
    };

    var _hide_bkg_layer = function()
    {
        $(bkg_class).removeClass('active');
        $(bkg_window_class).removeClass('active');
    };

    var _attach_bkg_event = function()
    {
        $(bkg_class).on('click', function(e)
        {

            _empty_iframe($('.mod.active'));
            $('.mod.active').removeClass('active');
            $('.mod.active').trigger('close-modal');
            _hide_bkg_layer();
            _init_scrolling();
        });

        $(bkg_window_class).on('click', function(e)
        {
            _empty_iframe($('.mod.active'));
            $('.mod.active').removeClass('active');
            $('.mod.active').trigger('close-modal');
            _hide_bkg_layer();
            _init_scrolling();
        });
    };

    var _stop_scrolling = function()
    {
        $('body').addClass('no-overflow');
    };

    var _init_scrolling = function()
    {
        $('body').removeClass('no-overflow');
    };

    var _show_newsletter_modal = function() {
        $('#newsletter').addClass('active');
        _show_bkg_layer();
        _stop_scrolling();
    };

    var _autoclick_single_catalog = function() {
        var single_id = $('.items.catalogues').data('single-id');
        if (single_id) {
            $('.items.catalogues a.modal-trigger[data-id="'+single_id+'"]').click();
        }
    };

    return {init: init};

})();
