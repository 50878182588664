var slider_door_collection_module = (function() 
{
	var init = function()
	{
		$('.door-slides').slick({
			infinite: true,
			centerMode: true,
			slidesToShow: 3,
			slidesToScroll: 3,
			//initialSlide: 1,
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true
				  }
				},
				{
				  breakpoint: 600,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				  }
				}
			]
		});
	};

	return {init: init};
})();