var slick_module = (function() {
    /**
     * Inizializza il modulo
     * @access  Public
     */
    var init = function() {
        _slick();
    };

    var _slick = function()
    {
        $('.slick-slider').slick({
            dots: false,
            infinite: true,
            arrows: false,
            fade: true,
            cssEase: 'ease',
            //swipe: false,
            // responsive: [
            // {
            //   breakpoint: 1025,
            //   settings: {
            //     swipe: true
            //   }
            // }]
        });

        $('.side-slickslider').slick({
            dots: false,
            infinite: true,
            arrows: false,
            fade: true,
            cssEase: 'ease',
            //swipe: false,
            // responsive: [
            // {
            //   breakpoint: 1025,
            //   settings: {
            //     swipe: true
            //   }
            // }]
        });

        $('.slider-nav .right').on('click', function(e) 
        {
            $('.slick-slider').slick('slickNext');
            $('.side-slickslider').slick('slickNext');
            $('.slider-container .left-col, .slider-container .right-col').toggleClass('active');
        });
        $('.slider-nav .left').on('click', function(e) 
        {
            $('.slick-slider').slick('slickPrev');
            $('.side-slickslider').slick('slickPrev');
            $('.slider-container .left-col, .slider-container .right-col').toggleClass('active');
        });

        $('.slider-container').on('swipe', function(event, slick, direction){
          switch(direction) {
            case 'left':
                $('.slick-slider').slick('slickPrev');
                $('.side-slickslider').slick('slickPrev');
                $('.slider-container .left-col, .slider-container .right-col').toggleClass('active');
                break;
            case 'right':
                $('.slick-slider').slick('slickNext');
                $('.side-slickslider').slick('slickNext');
                $('.slider-container .left-col, .slider-container .right-col').toggleClass('active');
                break;
          }
          // left
        });
    };

    var _destroy = function()
    {
        $('.slick-slider').slick('unslick');
        $('.side-slickslider').slick('unslick');
    };

    return {
        init: init,
        destroy: _destroy
    };
})();