var masonry_module = (function() 
{
    var _init = function()
    {
        _masonry();
    };

    var _masonry = function()
    {
        var $grid = $('.masonry').masonry({
          // options
          itemSelector: '.masonry-item',
          percentPosition: true,
          gutter: 0,
          columnWidth: '.masonry-sizer'
        });

        $grid.imagesLoaded().progress( function() {
          $grid.masonry('layout');
        });
    };

    return {init: _init};
    
})();