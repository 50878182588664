var slider_module = (function() 
{
	var header_wrapper = '.main-header';
	var slider_wrapper = '.slider-wrapper';
	var slider_arrows = '.slide-trigger';
	var detailClass = '.details-container';
	var footerClass = 'footer';
	var actual_slide = 0;

	var init = function()
	{
		_setEventListeners();
	};

	var _setEventListeners = function()
	{
		//Listener per frecce slider
		$(slider_arrows).on('click', function(e) 
		{
			var $slider = $(this).closest('.carousel-container').find('.carousel');
			var direction = $(this).data('direction');
			var next_slide;
			var $slides = $slider.find('.slide');
			var $actual_slide = $slides.filter('.active');

            $slides.removeClass('slideInLeft slideInRight');

			if(direction == 'next')
				next_slide = actual_slide+1;
			else
				next_slide = actual_slide-1;

			if (next_slide >= $slides.length) {
				next_slide = 0;
			} else if (next_slide < 0) {
				next_slide = $slides.length - 1;
			}

			var $next_slide = $slides.eq(next_slide);

			if($next_slide.length)
			{
				$actual_slide.addClass('slideOut');
				actual_slide = next_slide;
				setTimeout(function() 
				{
					$actual_slide.removeClass('slideOut').removeClass('active');
					if(direction == 'next')
						$next_slide.addClass('active').addClass('slideInRight');
					else
						$next_slide.addClass('active').addClass('slideInLeft');
				}, 400);
			}

		});
	};

	return {init: init};
})();