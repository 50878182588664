/*Modulo gestione animazione allo scroll della pagina*/
var gravity_forms_loader = (function() {
    /**
     * Esempio di variabile privata
     */
    var form_id;
    /**
     * Inizializza il modulo
     * @access  Public
     */
    var init = function() {
        _fancy();
    };

    var _init_form = function(_form_id, _append_selector)
    {
        $.get($ajax_url + '?action=load_gf_form&form_id=' + _form_id,function(response){
            $(_append_selector).html(response).fadeIn();
        });
    };

    return {
        init_form: _init_form
    };
})();