/*Modulo gestione mappa contatti*/
var dropdown = (function() 
{
    var dropdown_trigger_class = '.dropdown-trigger';
    var dropdown_element_class = '.dropdown-container ul li a';

    var init = function() 
    {
        if ($('#moreInfo_btn').length) {
            $('#moreInfo_btn').click(function (e) {
                e.preventDefault();
                _init_dropdown();
                _update_selects();
            });
        }else{
            _init_dropdown();
            }
    };
    

    var _init_dropdown = function()
    {
        //Click sul trigger del dropdown
        $('body').on('click', dropdown_trigger_class, function(e)
        {
            e.preventDefault();

            if( $(this).hasClass('active') )
            {
                $(this).removeClass('active');
                $(this).next().slideUp(200);
                $('body').removeClass('no-overflow');
            }
            else
            {
                $(this).addClass('active');
                $(this).next().slideDown(200);
                $('body').addClass('no-overflow');
            }
        });

        //Click su opzione
        $('body').on('click', dropdown_element_class, function(e)
        {
            e.preventDefault();

            $('body').removeClass('no-overflow');

            var target_form = $(this).data('target') ? $(this).data('target') : null;
            var submit_form = $(this).data('form') ? $(this).data('form') : null;
            var target_field = $(this).data('field') ? $(this).data('field') : null;
            var target_select = $(this).data('select') ? $(this).data('select') : null;

            $dropdownGroup = $(this).parents('.dropdown-container');

            $dropdownGroup.find(dropdown_trigger_class).removeClass('active');
            $dropdownGroup.find(dropdown_trigger_class).next().slideUp(200);

            var selected_value = $(this).data('value');
            var selected_label = $(this).data('label');

            _update_sel_label($dropdownGroup, selected_label);
            if($dropdownGroup.find('.hidden-value').length)
                _update_sel_input($dropdownGroup, selected_value);

            if (submit_form && target_field) {
                $(submit_form).find(target_field).val(selected_value);
            }

            if (target_select) {
                $(target_select).val(selected_value).change();
            }

            if(target_form)
                _open_target_form(target_form);
            else if (submit_form) {
                _submit_form(submit_form);
            }
        });
    };

    var _update_selects = function()
    {
        $('.gfield_select').each(function() {

            var $options = $(this).find('option');
            var $select = $(this);

            var html = '<div class="dropdown-container dropdown-input"><button class="dropdown-trigger"><span class="sel-label">' + $options.first().text() + '</span></button>';
            html += '<div class="dropdown">';
                html += '<ul>';

                    $options.each(function(i) {
                        if(!i)
                        {
                            html += '<li><a data-label="'+ $(this).text() +'" href="#" data-select="#'+ $select.attr('id') +'"><strong>'+ $(this).text() +'</strong></a></li>';
                        } 
                        else
                        {
                            html += '<li><a data-value="'+ $(this).attr('value') +'" data-label="'+ $(this).text() +'" href="#" data-select="#'+ $select.attr('id') +'">'+ $(this).text() +'</a></li>';
                        }
                    });

                html += '</ul>';
            html += '</div>';

            html += '<i class="icon-select"></i></div>';

            $select.after(html);
            $select.hide();
        });
    };

    var _open_target_form = function(_target)
    {
        $('.hidden-form').slideUp(slow, function() {$(_target).slideDown();});
    };

    var _update_sel_input = function(_$dropdown, _val)
    {
        _$dropdown.find('.hidden-value').val(_val);
    };

    var _update_sel_label = function(_$dropdown, _val)
    {
        _$dropdown.find(dropdown_trigger_class).find('.sel-label').text(_val);
    };

    var _submit_form = function(_form) {
        $(_form).submit();
    }

    return {
        init: init,
        updateSelects: _update_selects
    };
    
})();




