var bookmarks_module = (function() {

    function init() {

        $('.add-to-favourites').click(function(e) {
           e.preventDefault();


           $('.logo-btn.preferiti a').click();
           $button = $(this);

           var remove = $button.hasClass('active');
           var action = remove ? 'remove_bookmark' : 'add_bookmark';
           var id = $button.data('id');
           var finishID = $('div.finish').first().data('finish-id');
           if ($('div.finish.active').length > 0) {
               finishID = $('div.finish.active').first().data('finish-id');
           }
           var bkid = id + '@' + finishID;

           if (remove) {
               $('.favourite-prod[data-id="'+bkid+'"]').css('opacity', 0.3);
           } else {
               $('.favourite-prod.nothing').addClass('hidden');
               $('.favourite-prod.empty').removeClass('hidden');
           }

           $.get($ajax_url + '?action='+action+'&id='+id+'&finish='+finishID).done(function(data) {

               if (remove) {
                   $('.favourite-prod[data-id="'+bkid+'"]').remove();

                   if ($('.favourite-prod').length <= 2) {
                       $('.favourite-prod.nothing').removeClass('hidden');
                   }
               } else {
                   $('.favourite-prod.empty').addClass('hidden').after(data);
               }

               var text = $button.text();
               $button.html('<i class="icon-favourite"></i>' + $button.data('alternate-title'));
               $button.data('alternate-title', text);
               $button.toggleClass('active');
           }).fail(function(err) {
               $('.favourite-prod[data-id="'+id+'"]').removeAttr('style');
               $('.favourite-prod.empty').addClass('hidden');

               if ($('.favourite-prod').length <= 2) {
                   $('.favourite-prod.nothing').removeClass('hidden');
               }
           });
        });

        $('body').on('click', '.favourite-prod .remove-favourite', function(e) {
            e.preventDefault();
            var $box = $(this).closest('.favourite-prod');
            var id = $box.data('id');
            var ids = id.split("@");
            $box.css('opacity', 0.3);
            $.get($ajax_url + '?action=remove_bookmark&id=' + ids[0] + '&finish=' + ids[1]).done(function(data) {
                $box.remove();

                if ($('.favourite-prod').length <= 2) {
                    $('.favourite-prod.nothing').removeClass('hidden');
                }

                if ($('div.finish[data-finish-id="' + ids[1] + '"]').length > 0) {
                    $('div.finish[data-finish-id="' + ids[1] + '"]').first().click();
                }

            }).fail(function(err) {
                $box.removeAttr('style');
                $('.favourite-prod.empty').addClass('hidden');
            });
        });

    }

    return {init: init}
})();