var gmap_module = (function() 
{
    var map_selector = '#map';
    var iconBase = __config.mediaUrl + "images/commons/";

    var _init_contacts = function()
    {
        setTimeout(function() 
        {
            var $map = $(map_selector);
            if($map.length)
                _init_map($map);
        }, 0);
    };

    var _init_map = function($el)
    {
        var marker_coords = $el.data('markers');
        var b = new google.maps.LatLngBounds();

        var mapLatLng = new google.maps.LatLng(43.443159,13.532745);

        if($('.main-wrapper').outerWidth() <= 767)
        {
            mapLatLng = new google.maps.LatLng(45.707284,9.729649);
        }
        
        var mapOptions = {
            zoom: 15,
            zoomControl: true,
            //center: mapLatLng,
            scrollwheel: false,
            //styles: map_style,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            navigationControl: true,
            zoomControl: true,
            scaleControl: true,
            draggable: true,
            mapTypeControl: false
        }
            
        var map = new google.maps.Map($el[0],mapOptions);

        for (var i=0; i<marker_coords.length; i++) {

            var coords = new google.maps.LatLng(marker_coords[i].lat, marker_coords[i].lng);
            b.extend(coords);
            new google.maps.Marker({
                position: coords,
                map: map,
                id: 'marker-' + (i+1)
                //icon : iconBase + 'marker.png'
            });
        }

        map.fitBounds(b);

        var listener = google.maps.event.addListener(map, "idle", function() {
            if (map.getZoom() > 15) map.setZoom(15);
            google.maps.event.removeListener(listener);
        });
    };

    /**** Mappa punti vendita ***/
    var map;
    var markers = [];
    var places = [
        {
            "id": "1",
            "category" : "g-point",
            "name": "Rivenditore Garofoli",
            "province": "Monza Brianza",
            "address": "Carnate",
            "infowindow": "<div class=\"infob\"><h3 class=\"title\">[name]</h3><p class=\"address\">[address]</p><div class=\"icon-text-btn\"><a data-target=\"[name]\" data-id=\"[id]\" href=\"#\" class=\"btn btn-default\">Vai al sito <i class=\"icon-arrow-next\"></i></a></div></div>",
            "coords": {
                "$id": "3",
                "x": "45.6498338",
                "y": "9.3812405"
            }
        },
        {
            "id": "2",
            "category" : "g-store",
            "name": "Garofoli STORE",
            "province": "Bergamo",
            "address": "Via I Maggio, 24124<br />Bergamo (BG) - Italia",
            "infowindow": "<div class=\"infob\"><h3 class=\"title\">[name]</h3><p class=\"address\">[address]</p><div class=\"icon-text-btn\"><a data-target=\"[name]\" data-id=\"[id]\" href=\"#\" class=\"btn btn-default\">Vai al sito <i class=\"icon-arrow-next\"></i></a></div></div>",
            "coords": {
                "$id": "5",
                "x": "45.6982642",
                "y": "9.6772698"
            }
        },
        {
            "id": "3",
            "category" : "reseller",
            "name": "Garofoli POINT ",
            "province": "Como",
            "address": "Como",
            "infowindow": "<div class=\"infob\"><h3 class=\"title\">[name]</h3><p class=\"address\">[address]</p><div class=\"icon-text-btn\"><a data-target=\"[name]\" data-id=\"[id]\" href=\"#\" class=\"btn btn-default\">Vai al sito <i class=\"icon-arrow-next\"></i></a></div></div>",
            "coords": {
                "$id": "7",
                "x": "45.8080597",
                "y": "9.0851765"
            }
        }
    ];
    var latlng, bound, infowindow;

    var _init_resellers = function()
    {
        var $map = $(map_selector);
        if($map.length)
            initializeGmap($map, '');
    };

    var initializeGmap = function($el, iconBase)
    {
        markers = [];
        bounds = new google.maps.LatLngBounds();
        var mapCenter = new google.maps.LatLng(43.443159,13.532745);

        var mapOptions = {
            zoom: 15,
            zoomControl: true,
            //center: mapLatLng,
            scrollwheel: false,
            //styles: map_style,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            navigationControl: true,
            zoomControl: true,
            scaleControl: true,
            draggable: true,
            mapTypeControl: false
        };

        var shopData = $el.data('places');
        var shops = shopData.shops;
        var center = shopData.center;
        var radiusOut = shopData.radiusOut;

        if (center.lat && center.lng) {
            mapCenter = new google.maps.LatLng(center.lat, center.lng);
        }

        var places = [];

        for (var i=0; i<shops.length; i++) {
            places.push({
                id: 'shop-' + (i+1),
                category: shops[i].shop_type == '30' ? 'g-store' : (shops[i].shop_type == '20' ? 'g-point' : (shops[i].shop_type == '10' ? 'reseller' : 'gold')),
                name: shops[i].title,
                address: shops[i].shop_address + ' ' + shops[i].shop_city + ', ' + shops[i].shop_postcode + ' ' + shops[i].shop_province,
                phone: shops[i].shop_phone ? '<p class=\"address\"><i class=\"icon-phone\"></i> '+ shops[i].shop_phone +'</p>' : '',
                mail: shops[i].shop_mail ? '<p class=\"address\"><a href="mailto:'+ shops[i].shop_mail +'">'+ shops[i].shop_mail +'</p>' : '',
                infowindow: "<div class=\"infob\"><h3 class=\"title\">[name]</h3><p class=\"address\">[address]</p>[phone][mail]<div class=\"icon-text-btn\"><a data-target=\"[name]\" data-id=\"[id]\" href=\"[site]\" target=\"_blank\" class=\"btn btn-default\">Vai al sito <i class=\"icon-arrow-next\"></i></a></div></div>",
                coords: {
                    x: shops[i].shop_lat,
                    y: shops[i].shop_lng
                },
                site: shops[i].shop_website
            })
        }

        map = new google.maps.Map($el[0],mapOptions);
        infowindow = new google.maps.InfoWindow({
            content : 'placeholder content text'
        });

        addMarkers(places);
        if (!places.length) {
            bounds.extend(mapCenter);
        }

        map.fitBounds(bounds);

        var listener = google.maps.event.addListener(map, "idle", function() {
            if (map.getZoom() > 15) map.setZoom(15);
            google.maps.event.removeListener(listener);
        });

        $("#radius-out").val(radiusOut);
    };

    var addMarkers = function(places) 
    {
        for (var i = 0; i < places.length; i++) {
            addMarker(places[i]);
        }
        return this;
    };

    var addMarker = function(place) 
    {
        var latLng = new google.maps.LatLng(place.coords.x, place.coords.y);
        bounds.extend(latLng);

        var category = place.category ? place.category : 'reseller';

        if ( typeof place.infowindow == "undefined")
            place.infowindow = false;

        var marker_icon;
        switch(category)
        {
            case 'g-store':
                marker_icon = iconBase + 'garofoli-store.png?v=2';
                break;

            case 'g-point':
                marker_icon = iconBase + 'garofoli-point.png?v=2';
                break;

            case 'reseller':
                marker_icon = iconBase + 'garofoli-shop.png?v=2';
                break;

            case 'gold':
                marker_icon = iconBase + 'garofoli-gold.png?v=2';
                break;
        }

        var marker = new google.maps.Marker({
            position : latLng,
            map : map,
            title : place.title,
            content : place.infowindow? buildInfowindow(place.infowindow, place) : false,
            icon : marker_icon
        });
        if (place.infowindow)
        {
            google.maps.event.addListener(marker, 'click', function() {
                map.panTo(this.position);
                infowindow.setContent(this.content);
                infowindow.open(map, this);
            });
        }

        markers.push(marker);
        return marker;
    };

    var buildInfowindow = function(pattern, place) 
    {
        var rex = new RegExp("\\[(.*?)\\]", "g");
        // "g" for global
        var matches = pattern.match(rex);
        var cleanedM;
        for (var m in matches) {
            cleanedM = matches[m].replace(/[\[\]']+/g, '');
            if ( typeof place[cleanedM] != "undefined")
                pattern = pattern.replace(matches[m], place[cleanedM]);
            if(matches[m] == '[bookingUrl]' && place[cleanedM] !== undefined)
            {
                pattern = pattern.replace('booking-online','booking-online show');
            }
        }
        return pattern;
    };

    return {
        init_contacts: _init_contacts,
        init_resellers: _init_resellers
    };


    
})();









