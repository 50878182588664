var load_more_module = (function() {

    function setWaypoints() {
        if ($('.ajax-loading').length > 0) {
            Waypoint.destroyAll();    
            var waypoint = new Waypoint({
                element: $('.ajax-loading').last(),
                handler: function(direction) {
                    $('.remote-load-list').click();
                },
                offset: 'bottom-in-view' 
            });
        }
    }

    function init() {
        window.load_more_loading = false;

        $('body').on('click', '.remote-load-list', function(e) {

            e.preventDefault();
            if (window.load_more_loading) {
                return false;
            }

            window.load_more_loading = true;
            $(this).prop('disabled', true);

            var url = $(this).data('url');
            var page = parseInt($(this).data('page')) + 1;
            var selector = $(this).data('container-selector');
            var taxonomy = $(this).data('taxonomy');
            var s = $(this).data('s');
            var term = $(this).data('term');
            var filters = $(this).data('filters');
            var filter_query = [];

            $.each(filters, function(key) {
                $.each(filters[key], function(index) {
                    filter_query.push(key + '[]=' + filters[key][index]);
                });
            });
			
			$(".row.items").find('.filter-preloader').remove();		
			var vg_url = window.location.origin;			
			preloader =  vg_url + '/wp-content/themes/garofoli/media/images/ajax-loader.gif';
			$( ".row.items" ).append( "<div class='filter-preloader'><img src='" + preloader + "'/></div>" );
			
            $.get(url + '&lang=' + $site_lang + '&paged=' + page  + '&q=' + s + '&selector=' + selector + '&taxonomy=' + taxonomy + '&term=' + term + '&' + filter_query.join('&')).done(function(res) {
                var html = $.parseHTML(res);

                $(selector).find('.ajax-loading').remove();

                if ($(html).filter('.left-col').length) {
                    $(selector).find('.left-col').append($(html).filter('.left-col').html());
                    $(selector).find('.right-col').append($(html).filter('.right-col').html());

                    if ($(html).filter('.ajax-loading').length) {
                        $(selector).append('<div class="col-xs-12 ajax-loading">' + $(html).filter('.ajax-loading').html() + '</div>');
                    }
                } else {
                    $(selector).append(html);
                }

                setTimeout(function() {
                    window.load_more_loading = false;
					$(".row.items").find('.filter-preloader').remove();
                    setWaypoints();
                }, 500);

                $(window).trigger('scroll');

            }).fail(function(err) {
                console.log(err);
                window.load_more_loading = false;
				$(".row.items").find('.filter-preloader').remove();
                alert('Error while loading more entries :(');
            });
        });

        setWaypoints();

    }

    return {init: init}
})();