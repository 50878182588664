/*Modulo gestione animazione allo scroll della pagina*/
var scroll_nav_module = (function() {
    /**
     * Esempio di variabile privata
     */
    var privateVar;
    /**
     * Inizializza il modulo
     * @access  Public
     */
    var init = function() {
        _nav_animation();
    };

    var _nav_animation = function()
    {
        $( window ).scroll( function() 
        {
            _check_nav_offset();
        });
    };

    var _check_nav_offset = function()
    {
        var window_scroll = $( window ).scrollTop();
        var $nav = $('nav');

        var sec_offset = $nav.offset();
        var sec_position = $nav.position();
        var sec_height = $nav.find('.visible-header').outerHeight();
        var sec_animation = 'fadeInBkg';

        if( window_scroll >= ( sec_offset.top + sec_height/2 ) )
        {
            $nav.addClass( sec_animation );
        }
        else
        {
            $nav.removeClass( sec_animation );
        }
    };

    return {
        init: init,
        check_nav_offset: _check_nav_offset
    };
})();