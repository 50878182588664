var menu_module = (function() {

    var brand_class = '.brand';
    var menu_trigger_class = '.menu-trigger';
    var menu_close_trigger_class = '.close-menu-trigger';
    var menu_container_class = '.menu';
    var menu_buttons_container = '.visible-header';
    var submenu_class = 'li.submenu-trigger > a';

    var init = function()
    {
        _menu_module();
    };

    var _menu_module = function()
    {
        $(menu_container_class).on('transitionend webkitTransitionEnd oTransitionEnd', function(e) 
        {
            if( !$(this).hasClass('active') )
                $(menu_container_class).removeClass('delay');
        });

        //Listener trigger menu
        $(menu_trigger_class).on('click', function(e) 
        {
            e.preventDefault();

            $(menu_container_class).addClass('active');
            $(this).parent().addClass('slide-out');
            $('body').toggleClass('no-overflow');
        });

        $(menu_close_trigger_class).on('click', function(e) 
        {
            e.preventDefault();

            $(menu_container_class).removeClass('active');
            $('.menu-icon.slide-out').removeClass('slide-out');
        });

        //Listener voci di menu
        $('#menu-main_menu > li > a').on('click', function(e) 
        {
            $('#menu-main_menu > li > a').removeClass('active');
            $(this).addClass('active');
        });

        //Listener submenu
        $(submenu_class).on('click', function(e) 
        {
            e.preventDefault();

            var $menu_voice_li = $(this).parent();
            var $menu_voice = $(this);
            var $submenu = $menu_voice.next();

            $(submenu_class).not($(this)).parent().removeClass('active');
            $(submenu_class).not($(this)).removeClass('active');
            $(submenu_class).not($(this)).next().removeClass('active');

            $menu_voice_li.toggleClass('active');
            $menu_voice.toggleClass('active');
            $submenu.toggle().toggleClass('active');
        });

        //Listener Ricerca
        $('.search-trigger').on('click', function(e)
        {
            e.preventDefault();

            $('.main-menu-row').toggleClass('searching');
            $('.search-row').toggleClass('searching');
        });
    };

    return {init: init};

})();