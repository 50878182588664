/*Modulo gestione animazione allo scroll della pagina*/
var scroll_module = (function() {
    /**
     * Esempio di variabile privata
     */
    var privateVar;
    /**
     * Inizializza il modulo
     * @access  Public
     */
    var init = function() {
        _scroll_animation();
    };

    var SmoothScroll = function() 
    {

        var $window = window;
        var _body = document.querySelector('body');
        var scrollTime = 1.2;
        var scrollDistance = 170;

        if ($window.addEventListener) {
            $window.addEventListener("mousewheel", function (event) {
                if (!_body.classList.contains('no-overflow')) {
                    event.preventDefault();
                    var delta = event.wheelDelta / 120 || -event.detail / 3;
                    var scrollTop = window.pageYOffset !== undefined ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
                    var finalScroll = scrollTop - parseInt(delta * scrollDistance);
                    TweenLite.to($window, scrollTime, {
                        scrollTo: {
                            y: finalScroll,
                            autoKill: true
                        },
                        ease: Power1.easeOut,
                        overwrite: 5
                    });
                }
            }, false);
        }
    };

    var _check_elements = function()
    {
        var window_scroll = $( window ).scrollTop();
        var offset = $( this ).offset();

        $( '.barba-container > section' ).each( function( i )
            {
                var sec_offset = $( this ).offset();
                var sec_position = $( this ).position();
                var sec_animation = 'active';
                
                if( window_scroll >= ( sec_offset.top - ( $( window ).outerHeight(true) * .9 ) ) )
                {
                    $( this ).addClass( sec_animation );
                }
                else
                {
                    $( this ).removeClass( sec_animation );
                }
            });

            // if($(window).outerWidth() <= 1024)
            // {
            //     $( '.animatedContentMobile' ).each( function( i )
            //     {
            //         var sec_offset = $( this ).offset();
            //         var sec_position = $( this ).position();
            //         var sec_animation = $( this ).data( 'animation' );

            //         if( window_scroll >= ( (sec_offset.top - sec_position.top) - ( $( window ).height() * 0.8 ) ) )
            //         {
            //             $( this ).addClass( sec_animation );
            //         }
            //         else
            //         {
            //             $( this ).removeClass( sec_animation );
            //         }
            //     });
            // }

            $('.mosaic .table-row').each( function(i) 
            {
                var sec_offset = $( this ).offset();
                var sec_position = $( this ).position();

                if( window_scroll >= ( sec_offset.top - ( $( window ).outerHeight(true) * 0.9 ) ) )
                {
                    $( this ).addClass( 'active' );
                }
                else
                {
                    $( this ).removeClass( 'active' );
                }
            });

            $('.mosaic .bottom-border').each( function(i) 
            {
                var sec_offset = $( this ).offset();
                var sec_position = $( this ).position();

                if( window_scroll >= ( sec_offset.top - ( $( window ).outerHeight(true) * 0.9 ) ) )
                {
                    $( this ).addClass( 'active' );
                }
                else
                {
                    $( this ).removeClass( 'active' );
                }
            });

            $('.mosaic .items .item').each( function(i) 
            {
                var sec_offset = $( this ).offset();
                var sec_position = $( this ).position();

                if( window_scroll >= ( sec_offset.top - ( $( window ).outerHeight(true) * 0.9 ) ) )
                {
                    $( this ).addClass( 'active' );
                }
                else
                {
                    $( this ).removeClass( 'active' );
                }
            });

            $('.search-results .items .item').each( function(i) 
            {
                var sec_offset = $( this ).offset();
                var sec_position = $( this ).position();

                if( window_scroll >= ( sec_offset.top - ( $( window ).outerHeight(true) * 0.9 ) ) )
                {
                    $( this ).addClass( 'active' );
                }
                else
                {
                    $( this ).removeClass( 'active' );
                }
            });
    };    

    var _scroll_animation = function()
    {
        $( window ).scroll( function() 
        {
            _check_elements();
        });
    };

    return {
        smoothScroll: SmoothScroll,
        init: init,
        check_elements: _check_elements
    };
})();

/*Modulo plug-in scroll customizzato*/
var custom_scroll_bar = (function() {

    var init = function() {
        _scroll_bar();
    };

    var _scroll_bar = function()
    {
        $('.custom-scroll-bar').mCustomScrollbar({
            theme:"dark"
        });
    };

    var _scroll_speed = function(_step, _velocity, _easing)
    {
        _easing = _easing !== undefined ? _easing : 'easeInCubic';
        $.scrollSpeed(_step, _velocity, _easing);
    };

    return {
        init: init,
        scrollSpeed: _scroll_speed
    };

})();







