var helper_module = (function() 
{
    var _do_the_magic = function()
    {
        scroll_module.init();
        scroll_nav_module.init();
        modal_module.init();
        custom_scroll_bar.init();
        filters_module.init();
        masonry_module.init();
        load_more_module.init();
        bookmarks_module.init();
        //custom_scroll_bar.scrollSpeed(30, 400, 'easeOutCubic');

        scroll_nav_module.check_nav_offset();
        setTimeout(function() {scroll_module.check_elements();}, 300);
    };

    return {do_the_magic: _do_the_magic};
    
})();