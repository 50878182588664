/**
 *
 * MAIN JAVASCRIPT MODULES: Sono i moduli principali.
 *
 * @author   Andrea Dell'Orco <ado@netnext.it>
 * @version  v 1.2
 *
 * Modulo core  NON ECMA 6
 */
var Engine = (function() {
    var functionsOnReady = [];
    /**
     * Avvia l'engine
     */
    function start() {
        executeOnReady();
    }
    /**
     * Esegue fisicamente le azioni in coda
     */
    var executeOnReady = function() {
        functionsOnReady.sort(compareRule);
        $(document).ready(function() {
            for (var i in functionsOnReady) {
                functionsOnReady[i].action();
            }
        });
    };
    /**
     * Regola di comparazione delle priorità
     * @param  {[type]} a
     * @param  {[type]} b
     * @return {any}
     */
    var compareRule = function(a, b) {
        if (a.priority > b.priority) return -1;
        else if (a.last_nom > b.last_nom) return 1;
        else return 0;
    };
    /**
     * Funzione che sostituisce la classica document ready di jquery
     * @param {Function}  action Funzione di callback da eseguire
     * @param {string =      "unkown"}    code     Codice identificativo dell'azione
     * @param {number =      1}           priority Priorità nell'esecuzione, maggiore è il valore, più alta è la priorità
     */
    function onReady(action, priority) {
        if (typeof priority == "undefined") priority = 1;
        functionsOnReady.push({
            action: action,
            priority: priority
        });
    }
    return {
        onReady:onReady,
        start:start
    };

})();
Engine.start();

Engine.onReady(function() {
    //Avvio Barba e tutti i moduli non compresi nel barba wrapper *BARBA*
    //transition_module.init();
    menu_module.init();
    scroll_module.smoothScroll();
    dropdown.init();
    helper_module.do_the_magic();

    //*BARBA*
    switch($('.barba-container').data('namespace'))
    {
        case 'homepage':
            scroll_module.init();
            slider_module.init();
            break;

        case 'ricerca':
            scroll_module.init();
            slider_module.init();
            break;

        case 'garofoli':
            slider_module.init();
            break;

        case 'porte':
            slider_module.init();
            slider_door_collection_module.init();
            break;

        case 'famiglia':
            slider_module.init();
            break;

        case 'contatti':
            dropdown.updateSelects();
            gmap_module.init_contacts();
            contacts_form.init();
            break;

        case 'punti-vendita':
            gmap_module.init_resellers();
            dropdown.updateSelects();
            break;
    };
    
    //*BARBA*
    //$('.loader').addClass('inactive');
    //setTimeout(function() { $('.loader').removeClass('first-load'); $('.loader').removeClass('inactive'); }, 1700);
});
