/*Modulo gestione mappa contatti*/
var contacts_form = (function() 
{
    var form_trigger = '.form-trigger';
    var form_container = '.form-container';
	var form_trigger_new = '.form-trigger-new';	

    var init = function( _$el ) 
    {
        _init_forms();
    };

    var _init_forms = function()
    {
        //Click sul pulsante form
        $(form_trigger).on('click', function(e) 
        {
            var $target_form = $($(this).data('form'));
            $(form_trigger).removeClass('active');
            
            $(this).addClass('active');
            
            $('.forms-wrapper').slideUp(300, function() {
                $('.form-container.active').removeClass('active');
                $target_form.addClass('active');
                $('.forms-wrapper').slideDown(300);
            });
        });

        //Click sul pulsante form
        $(form_trigger_new).on('click', function(e) 
        {
            var $target_form = $($(this).data('form'));
            $(form_trigger_new).removeClass('active');
            
            $(this).addClass('active');
			
            //$('.forms-wrapper').slideUp(300, function() {
                $('.form-container.active').removeClass('active');
                $target_form.addClass('active');
               // $('.forms-wrapper').slideDown(300);
            //});
        });

        var hash = window.location.hash;
        if (hash.match(/#gf_\d/)) {

            var $anchor = $(hash);
            var container_id = $anchor.closest('.form-container').attr('id');

            setTimeout(function() {
                				
				var form_click= $('[data-form="#'+container_id+'"]');				
				var sel_label = $('a',form_click).data('label')  ;
				$(".collections-filters .sel-label").text(sel_label);								
				form_click.click();	
				
				//$('[data-form="#'+container_id+'"]').click();  

                var $grandParent = $anchor.closest('.block-form').parent();
                if ($grandParent.hasClass('hidden-form')) {
                    var $container = $grandParent.parent();
                    var parentIndex = $container.find('.hidden-form').index($grandParent);

                    if (parentIndex >= 0) {
                        $container.find('.form-switch ul li a').eq(parentIndex).click()
                    }
                }

                //$(document).scrollTop($anchor.offset().top);
            }, 500);
			
        }else{

			var url_string = (window.location.href).toLowerCase();
			var url = new URL(url_string);
			var container_id = url.searchParams.get("id");	

			var url_base = window.location.href.split('?')[0];
			//history.pushState({},null, url_base);		
			if (url_string.indexOf('#') < 1){
				history.pushState({},null, url_base);
			}
			
			if (!container_id) {
				container_id = 'catalogo';
			}			
			var form_click= $('[data-form="#'+container_id+'"]');				
			var sel_label = $('a',form_click).data('label')  ;
			$(".collections-filters .sel-label").text(sel_label);								
			form_click.click();				
			$('#' +container_id + '.form-container').addClass('active');	
		}

    };

    return {init: init};
    
})();

jQuery(document).ready(function() {
    $('body').on('submit', '.catalog-form', function() {
        var url = $(this).attr('action');
        var loading = true;
        var data = $(this).serializeArray();
        data.push({
            name: 'catalog_request[catalog_id]',
            value: $(this).closest('.mod').data('source-id')
        });

        $inputs = $(this).find(':input');
        $form = $(this);

        $form.css('opacity', 0.3);
        $inputs.attr('disabled', 'disabled');

        $.post(url, $.param(data)).done(function(data) {
            $('#modal-catalogue .modal-cont').empty().append(data);
            loading = false;
        }).fail(function(err) {
            console.log(err);
            loading = false;

            $form.removeAttr('style');
            $inputs.removeAttr('disabled');
            alert('Error while submitting the form :(');
        });

        return false;
    });

    $('form.ajax-login').on('submit', function() {

        var $form = $(this);
        var $loader = $form.find('.loader').removeClass('hidden');
        var $message = $form.find('.message').empty();

        $.post($(this).attr('action'), $(this).serialize()).done(function(data) {
            var response = JSON.parse(data);
            $message.text(response.message);

            if (response.loggedin)
                window.location.reload();

            $loader.addClass('hidden');

        }).fail(function(xhr) {
            console.log(xhr);
            $message.text('Errore del server. Riprovare');
            $loader.addClass('hidden');
        });

        return false;

    });

    $('body').on('submit', 'form.change-password', function() {
        var url = $(this).attr('action');
        var loading = true;
        var data = $(this).serialize();

        $inputs = $(this).find(':input');
        $form = $(this);

        $new_password = $form.find('[name="new_password"]');
        $old_password = $form.find('[name="old_password"]');

        $form.css('opacity', 0.3);
        $inputs.attr('disabled', 'disabled');

        $.post(url, data).done(function(data) {
            if (data.success) {
                $form.addClass('hidden');
                $form.find('span.error').remove();
                $form.next('.change-password-success').removeClass('hidden');
            } else {
                $new_password.next('span').remove();
                $old_password.next('span').remove();

                if (data.errors.new_password) {
                    $new_password.after('<span class="error">' + data.errors.new_password + '</span>');
                }

                if (data.errors.old_password) {
                    $old_password.after('<span class="error">' + data.errors.old_password + '</span>');
                }
            }

        }).fail(function(err) {
            console.log(err);
            alert('Error while submitting the form :(');
        }).always(function() {
            $form.removeAttr('style');
            $inputs.removeAttr('disabled');
            loading = false;
        });

        return false;
    });
});