/**
 *
 * Modulo per gestione accettazione della cookie policy.
 *
 * @author   Andrea Dell'Orco <ado@netnext.it>
 * @version  v 1.0
 */
var Policy = (function() {
    var cookieAccepted = false;
    var functionsOnReady = [];

    /**
     * Avvia l'engine 
     */
    function accept() {
        cookieAccepted = true;
        callbackCookie();
    }

    /**
     * Esegue fisicamente le azioni in coda
     */
    var executeOnReady = function() {
        functionsOnReady.sort(compareRule);
        for (var i in functionsOnReady) {
            functionsOnReady[i].action();
        }
        
    };

    /**
     * Regola di comparazione delle priorità
     * @param  {[type]} a 
     * @param  {[type]} b 
     * @return {any}      
     */
    var compareRule = function(a, b) {
        if (a.priority > b.priority) return -1;
        else if (a.last_nom > b.last_nom) return 1;
        else return 0;
    };

    /**
     * Funzione che permette di mettere in coda le funzioni che possono essere lanciate solo ad accettazione della privacy.
     * Questa function esegue subito l'azione in caso di cookie accettati, altrimenti mette l'azione in coda. Si consiglia di lanciarla solo ad engine ready
     * @param {Function}  action Funzione di callback da eseguire
     * @param {string = "unkown"}    code     Codice identificativo dell'azione
     * @param {number = 1}           priority Priorità nell'esecuzione, maggiore è il valore, più alta è la priorità
     */
    function onReady(action, priority) {

        if(cookieAccepted){
            action();
            return;
        }

        if (typeof priority == "undefined") priority = 1;
        functionsOnReady.push({
            action: action,
            priority: priority
        });
    }

    /**
     * Esegue tutto quello che deve eseguire quando si attivano i cookie
     */
    function callbackCookie(){
        executeOnReady();
    }

    return{
        cookieAccepted:cookieAccepted,
        onReady:onReady,
        accept:accept
    };
})();
/*ES:

Privacy.onReady(function() {
    example_module.init();
});

*/

/**
 * Se è presente Iubenda, all'accettazione dei cookie viene inizializzata la cookie policy. 
 * Si può rimuovere questo blocco (e andrebbe fatto) se Iubenda non è presente e la gestione è manuale.
 * Se iubenda non è presente, l'accettazione viene fatta a document ready al momento
 * @author  Andrea Dell'Orco
 *
 */
if (typeof _iub != "undefined") _iub.csConfiguration.callback = {
    onConsentGiven: function() {
        Engine.onReady(function() {
             Policy.accept();
        }, 100);
    }
};
else Engine.onReady(function() {
    Policy.accept();
}, 100);

