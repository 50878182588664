/*Modulo gestione filtri*/
var filters_module = (function() 
{
    var filter_class = '.filter-block .filter';
    var reset_class = '.reset-filters';
    var selected_filters = [];

    var _init = function()
    {
        _attach_events();
    };

    var _attach_events = function()
    {
        /*$(document).on('click', filter_class, function(e)
        {
            var $filter = $(e.target).parents('.filter');
            var filter_value = $(this).data('value');
            var target_class = $(e.target).attr('class');

            switch(target_class)
            {
                case 'icon-close':
                    _remove_filter(filter_value);
                    $filter.removeClass('active');
                    console.log(selected_filters);
                    break;

                case 'text':
                    if(!$filter.hasClass('active'))
                        _update_filters(filter_value);
                    $filter.addClass('active');
                    break;
            }
        });*/

        //Click su reset filtri
        $(document).on('click', reset_class, function(e) 
        {
            _reset_filters();            
        });

    };

    var _update_filters = function(_value)
    {
        selected_filters.push(_value);
    };

    var _remove_filter = function(_value)
    {
        var index = selected_filters.indexOf(_value);
        if (index > -1) {
            selected_filters.splice(index, 1);
        }
    };

    var _reset_filters = function()
    {
        $(filter_class).find('input[type="checkbox"]').prop('checked', false);

        selected_filters = [];
    };

    return {init: _init};
    
})();